import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const Covid19Page = () => (
  <Layout>
    <SEO title="Covid 19" />
    
    
    
  </Layout>
)

export default Covid19Page